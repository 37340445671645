<template>
  <main class="user">
    <b-breadcrumb :items="breadcrumb" />
    <div class="container" :class="{ 'mx-auto': !sidebarShow }">
      <section class="section">
        <div class="section-title user-title">All Users</div>
        <div class="section-table user-table">
          <div class="section-table-status">
            <div class="row">
              <div class="col-auto">
                <router-link class="btn btn-primary" to="/platform/user/new"
                  >New User</router-link
                >
              </div>
            </div>
          </div>
          <div class="section-table-content" v-if="usersShow">
            <CDataTable
              class="user-dataTable"
              :items="usersData"
              :fields="fields"
              :table-filter="{
                label: 'Search:',
                placeholder: ''
              }"
              :items-per-page="10"
              :pagination="{ align: 'center' }"
              :itemsPerPageSelect="{ values: [10, 20, 50, 100] }"
              hover
              :sorter="{ resetable: true }"
            >
              <template #sorting-icon="{state}">
                <TableSortingIcon :state="state"></TableSortingIcon>
              </template>
              <template #subscription="{item}">
                <td>
                  <router-link
                    v-if="item.subscription"
                    class="btn-link"
                    :to="`/platform/subscription/${item.subscriptionId}/view`"
                    >{{ item.subscription }}
                  </router-link>
                </td>
              </template>
              <template #show_details="{item}">
                <td>
                  <div class="dataTable-btns" v-if="isAdmin">
                    <router-link
                      class="btn btn-outline-primary btn-sm dataTable-btn"
                      :to="`/platform/user/${item.id}/edit`"
                    >
                      Edit
                    </router-link>
                    <button
                      class="btn btn-outline-danger btn-sm dataTable-btn"
                      @click.prevent="deleteModalOpen(item)"
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </template>
            </CDataTable>
          </div>
        </div>
      </section>
    </div>
    <ModalDelete
      :isOpen="deleteModalShow"
      :deleteData="deleteData"
      @yes="userDelete"
      @no="deleteModalCancel"
    />
  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { CDataTable } from '@coreui/vue/src'
import TableSortingIcon from '../components/TableSortingIcon.vue'
import ModalDelete from '../components/ModalDelete.vue'

export default {
  name: 'User',
  components: { CDataTable, TableSortingIcon, ModalDelete },
  data() {
    return {
      breadcrumb: [
        {
          text: 'User',
          active: true
        }
      ],
      usersShow: false,
      fields: [
        { key: 'username', label: 'Name', _style: 'min-width:150px' },
        { key: 'email', label: 'Email', _style: 'min-width:200px' },
        {
          key: 'subscription',
          label: 'Subscription',
          _style: 'min-width:150px;'
        },
        { key: 'role', label: 'Role', _style: 'min-width:150px;' },
        {
          key: 'show_details',
          label: '',
          sorter: false,
          filter: false
        }
      ],
      deleteModalShow: false,
      deleteData: {}
    }
  },
  async mounted() {
    let status = await this['user/pageHomeCreated']()
    if (status === 'success') {
      this.usersShow = true
    } else {
      console.log('user created: ' + status)
      this.$router.push('/pages/page403')
    }
  },
  computed: {
    ...mapState({
      sidebarShow: state => state.sidebarShow,
      userSelf: state => state.user.self,
      users: state => state.user.list
    }),
    usersData() {
      if (!this.users) return []
      return this.users.map(user => {
        const { id, firstName, lastName, email, subscription, role } = user
        return {
          id,
          username: `${firstName} ${lastName}`,
          email,
          subscriptionId: subscription ? subscription.id : null,
          subscription: subscription ? subscription.companyName : null,
          role
        }
      })
    },
    isAdmin() {
      return this.userSelf.role === 'SYSTEM_ADMIN'
    }
  },
  methods: {
    ...mapActions(['user/pageHomeCreated', 'user/pageHomeDelete']),
    deleteModalOpen(user) {
      this.deleteModalShow = true
      this.deleteData = {
        ...user,
        deleteType: 'user'
      }
    },
    deleteModalCancel() {
      this.deleteModalShow = false
      this.deleteData = {}
    },
    async userDelete() {
      this.deleteModalShow = false
      const id = this.deleteData.id
      let status = await this['user/pageHomeDelete']({ id })
      if (status === 'success') {
        this.deleteData = {}
      } else {
        this.deleteData = {}
        console.log('User delete: ' + status)
        // this.$router.push('/pages/page403')
      }
    },
    isDropdownDisplay(userRole) {
      let selfRole = this.userSelf.role
      switch (selfRole) {
        case 'SYSTEM_ADMIN':
          return true
        case 'CUSTOMER_SUCCESS':
          return userRole !== 'SYSTEM_ADMIN'
      }
    }
  }
}
</script>
